import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  MenuItem,
  Select,
  Avatar,
  Box,
  Drawer,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom"; 

// Styled components
const StyledCalendar = styled(Calendar)`
  width: 100% !important;
  max-width: 1200px;
  border: none;
  margin: 0 auto; /* Center horizontally */
  background-color: #81b9bb;

  .react-calendar__tile {
    padding: 25px;
    text-align: center;
    background-color: white; /* Background color */
    color: #000000; /* Text color */
    border: 1px solid white; /* Border color and thickness */
    border-radius: 0.5px; /* Round the corners */
    font-weight: bold;
    font-size: 16px;
    pointer-events: auto;
  }

  .react-calendar__navigation {
    display: none;
  }

  .react-calendar_month-viewdays_day--weekend {
    background-color: #b3afaf;
  }

  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #b0b0b0;
  }

  .react-calendar__tile--now {
    background-color: white;
    color: green;
    border: 2px solid #d4a8f4;
  }

  .react-calendar__tile--active {
    background-color: #e0e0e0;
    color: #000000;
  }

  .highlighted-present {
    background-color: #5bb659 !important;
    color: white !important;
  }

  .highlighted-halfDay {
    background-color: #7b81e1 !important;
    color: white !important;
  }

  .highlighted-sunday {
  background-color: #A99E9E; /* Set the background color to black */
  color: white; /* Set the text color to white for contrast */
}

  .highlighted-absent {
    background-color: #F55A5C !important;
    color: white !important;
  }

  .highlighted-leave {
    background-color: #fae79b !important;
    color: white !important;
  }
`;

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(9),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const StatusCard = styled(Box)(({ theme, status }) => ({
  backgroundColor:
    status === "Present"
      ? "#9FE39D"
      : status === "Absent"
      ? "#F55A5C"
      : status === "halfDay"
      ? "#7B81E1"
      : status === "week off"
      ? "#A99E9E "
      : "#eeeeee",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  borderLeft: `4px solid ${
    status === "Present"
      ? "#4caf50"
      : status === "Absent"
      ? "#f44336"
      : status === "halfDay"
      ? "#1620CC"
      : "#9e9e9e"
  }`,
}));

const EmployeeAttendanceDetails = () => {
  const { empId, employeeId } = useParams();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(calendarDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(calendarDate.getFullYear());
  const [attendanceDates, setAttendanceDates] = useState([]);
  const [halfDayAttendanceDates, setHalfDayAttendanceDates] = useState([]);
  const [leaveDates, setLeaveDates] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [halfDayCount, setHalfDayCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [absentDatesCount, setAbsentDatesCount] = useState(0);
  const [weekOffCount, setWeekOffCount] = useState(0);
  const [loadingButton, setLoadingButton] = useState(""); // State to track which button is loading
  const [loading, setLoading] = useState(true); // State for avatar loading

  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for Drawer (Side Modal)
  const [selectedDate, setSelectedDate] = useState(null);

  const [halfDayAttendanceRecords, setHalfDayAttendanceRecords] = useState([]);

  // present need to update to absent
  const [newEmployeeId, setNewEmployeeId] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const onDateChange = (date) => {
    setSelectedDate(date); // Set selected date
    setIsDrawerOpen(true); // Open the drawer when a date is clicked
  };

  useEffect(() => {
    fetchEmployees();
    fetchMonthlyAttendance();

    fetchNumberOfSundays(selectedYear, selectedMonth);
  }, [empId, selectedYear, selectedMonth]);

  // Image loading code
  useEffect(() => {
    if (employeesData.length > 0) {
      const image = new Image();
      image.src = employeesData[0].profilePic;
      image.onload = () => {
        setLoading(false);
      };
    }
  }, [employeesData]);

  // Fetch Employee Data
  const fetchEmployees = async () => {
    try {
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/employee/getById/${empId}`
      );
      const data = await response.json();

      const employeeWithImage = {
        ...data,
        profilePic: `data:image/jpeg;base64,${data.profilePic}`,
      };

      setEmployeesData([employeeWithImage]);
      setNewEmployeeId(employeeWithImage.employeeId);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  // Fetch Attendance Counts
  const fetchAttendanceCounts = async () => {
    try {
      await fetchPresentCount();
      await fetchHalfAttendance(selectedMonth, selectedYear);
      await fetchHalfDayCount();
    } catch (error) {
      console.error("Error fetching attendance counts:", error);
    }
  };

  //Present Count
  const fetchPresentCount = async () => {
    try {
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/attendance/count-by-month-year/${empId}/${selectedYear}/${
          selectedMonth + 1
        }`
      );
      const data = await response.json();
      setPresentCount(data);
    } catch (error) {
      console.error("Error fetching present count:", error);
    }
  };

  //halfDay Count
  const fetchHalfDayCount = async () => {
    try {
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/attendance/halfDayCount/${empId}/${selectedYear}/${
          selectedMonth + 1
        }`
      );
      const data = await response.json();
      setHalfDayCount(data);
    } catch (error) {
      console.error("Error fetching present count:", error);
    }
  };

  // GET Method- for monthly Attendance
  const fetchMonthlyAttendance = async () => {
    try {
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/attendance/employee/monthly/${empId}/${selectedYear}/${
          selectedMonth + 1
        }`
      );
      const data = await response.json();

      // Convert each API date to a local date string in YYYY-MM-DD format
      const presentDates = data.map((record) => {
        const dateObj = new Date(record.date);
        return dateObj.toLocaleDateString("en-CA");
      });

      setAttendanceDates(presentDates);
    } catch (error) {
      console.error("Error fetching monthly attendance:", error);
    }
  };

  // GET Method- for monthly Attendance
  const fetchHalfAttendance = async () => {
    try {
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/attendance/employee/HalfDay/monthly/${empId}/${selectedYear}/${
          selectedMonth + 1
        }`
      );
      const data = await response.json();

      // Convert each API date to a local date string in YYYY-MM-DD format
      const presentDates = data.map((record) => {
        const dateObj = new Date(record.date);
        return dateObj.toLocaleDateString("en-CA");
      });

      console.log("i am HalfdayAttendance: " + presentDates);

      setHalfDayAttendanceDates(presentDates);
    } catch (error) {
      console.error("Error fetching monthly attendance:", error);
    }
  };

  // GET method for "Leave Requests"
  const fetchLeaveRequests = async () => {
    try {
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/leave/leaveRequestsBy/${empId}/${selectedYear}/${
          selectedMonth + 1
        }`
      );
      const data = await response.json();

      // Extract all leave dates between startDate and endDate
      const dates = [];
      data.forEach((leave) => {
        const [startYear, startMonth, startDay] = leave.startDate;
        const [endYear, endMonth, endDay] = leave.endDate;

        const startDate = new Date(startYear, startMonth - 1, startDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);

        let currentDate = startDate;
        while (currentDate <= endDate) {
          dates.push(currentDate.toLocaleDateString("en-CA"));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      });

      setLeaveDates(dates);
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  //side model buttons code
  const handleAttendanceChange = async (status) => {
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString("en-CA"); // Format date as 'YYYY-MM-DD'
      setLoadingButton(status); // Start loader for the specific button

      if (status === "Absent") {
        console.log("Updating status to absent");

        const url = `https://login.hrbppayroll.com/hrbp/api/attendance/absent/${newEmployeeId}/${selectedYear}/${
          selectedMonth + 1
        }/${selectedDate.getDate()}`;

        try {
          const response = await fetch(url, {
            method: "DELETE",
          });

          if (response.ok) {
            console.log("Attendance record deleted successfully");
            fetchAttendanceCounts();
            fetchMonthlyAttendance();
            setIsDrawerOpen(false);
          } else {
            console.error("Failed to delete attendance record");
          }
        } catch (error) {
          console.error("Error while deleting attendance record:", error);
        } finally {
          setLoadingButton(""); // Stop loader
        }
      } else if (status === "Present") {
        console.log("Marking attendance as present");

        const url = `https://login.hrbppayroll.com/hrbp/api/attendance/present/${newEmployeeId}/${selectedYear}/${
          selectedMonth + 1
        }/${selectedDate.getDate()}`;

        const requestBody = {
          date: formattedDate,
          employeeId: newEmployeeId,
        };

        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          if (response.ok) {
            console.log("Attendance marked as present");

            // Refresh attendance data after marking as present
            await fetchAttendanceCounts();
            await fetchMonthlyAttendance(); // Ensure this is called to refresh calendar
            setIsDrawerOpen(false);
          } else {
            console.error("Failed to mark attendance as present");
          }
        } catch (error) {
          console.error("Error while marking attendance as present:", error);
        } finally {
          setLoadingButton(""); // Stop loader
        }
      } else if (status === "halfDay") {
        console.log("Marking attendance as half-day");

        const url = `https://login.hrbppayroll.com/hrbp/api/attendance/halfDay/${newEmployeeId}/${selectedYear}/${
          selectedMonth + 1
        }/${selectedDate.getDate()}`;

        const requestBody = {
          date: formattedDate,
          employeeId: newEmployeeId,
        };

        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          if (response.ok) {
            const data = await response.json();
            if (data.numberOfHours < 4) {
              // Store the date in the correct format to match the tileClassName format
              const dateKey = selectedDate.toLocaleDateString("en-CA");
              setHalfDayAttendanceRecords((prev) => [...prev, dateKey]); // Add to half-day records
              console.log("Attendance marked as half-day:", response);
            }

            // Refresh attendance data after marking as half-day
            await fetchAttendanceCounts();
            await fetchMonthlyAttendance(); // Ensure this is called to refresh calendar
            setIsDrawerOpen(false);
          } else {
            console.error("Failed to mark attendance as half-day");
          }
        } catch (error) {
          console.error("Error while marking attendance as half-day:", error);
        } finally {
          setLoadingButton(""); // Stop loader
        }
      }
    }
  };

  const fetchNumberOfSundays = async () => {
    try {
      const response = await fetch(
        ` https://login.hrbppayroll.com/hrbp/api/attendance/count-week-off/${selectedYear}/${
          selectedMonth + 1
        }`
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch number of Sundays: ${response.statusText}`
        );
      }

      const sundayCount = await response.json(); // Assuming the response is a simple integer
      setWeekOffCount(sundayCount);
    } catch (error) {
      console.error("Error fetching number of Sundays:", error);
    }
  };

  // Handles year and month selection
  const handleYearMonthChange = (event) => {
    const [year, month] = event.target.value.split("-");
    setSelectedYear(parseInt(year));
    setSelectedMonth(parseInt(month) - 1);
  };

  


  useEffect(() => {
    const absentCount = calculateAbsentDatesCount();
    setAbsentDatesCount(absentCount);
  }, [attendanceDates, selectedYear, selectedMonth]);

  // Function to calculate absent dates count
  const calculateAbsentDatesCount = () => {
    let count = 0;
    const currentMonthDates = new Date(
      selectedYear,
      selectedMonth + 1,
      0
    ).getDate(); // Total days in selected month

    for (let day = 1; day <= currentMonthDates; day++) {
      const date = new Date(selectedYear, selectedMonth, day);
      const dateStr = date.toLocaleDateString("en-CA");

      // Only count past dates that are not present in attendanceDates
      if (date <= new Date() && !attendanceDates.includes(dateStr)) {
        count++;
      }
    }
    return count;
  };

  // Add the class for specific dates
  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const dateStr = date.toLocaleDateString("en-CA"); // Ensure the format matches

          // Check if the date is a Sunday
    if (date.getDay() === 0) { // 0 represents Sunday
      return "highlighted-sunday"; // Add a class for Sundays
    }


      if (attendanceDates.includes(dateStr)) {
        return "highlighted-present"; // Green for present dates
      }

      if (leaveDates.includes(dateStr)) {
        return "highlighted-leave"; // Yellow for leave dates
      }

      // Correct class name for half-day attendance
      if (halfDayAttendanceDates.includes(dateStr)) {
        return "highlighted-halfDay"; // Pink for half-day attendance
      }

      if (date <= new Date() && !attendanceDates.includes(dateStr)) {
        return "highlighted-absent"; // Red for absent past dates
      }
    }
    return null;
  };







  const handleGetClick = () => {
    // Set calendarDate to the first day of the selected month and year
    const newDate = new Date(selectedYear, selectedMonth, 1);
    setCalendarDate(newDate);
    // Fetch the updated data
    fetchAttendanceCounts();
    fetchMonthlyAttendance();
    fetchLeaveRequests();
    setAbsentDatesCount(calculateAbsentDatesCount()); // Calculate and set the absent count
  };










  //back Arrow
  // const backToEmployeeDetails=() => {
  //   navigate('/')
  // }

  const tileDisabled = ({ date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date > today;
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    // setTimeout(() => {
    //   window.location.(); // Reloads the entire page
    // }, 100); // Delay to ensure the drawer closes first
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"} // Stack items vertically on mobile
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        // mt={1}
      >
        {loading ? (
          <Typography variant="body1" sx={{ fontSize: "30px" }} ml={5}>
            Loading.....
          </Typography>
        ) : (
          employeesData.length > 0 && (
            <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
              {/* ArrowBackIcon added here */}
              <ArrowBackIcon
                style={{
                  marginRight: isMobile ? 0 : 15, // Adjust margin on mobile and desktop
                  fontSize: "30px", // Adjust size as needed
                  cursor:'pointer'
                }}

                onClick={() => navigate(`/employeeDetails/${empId}`)}
              />

              <Avatar
                alt={`${employeesData[0].firstName} ${employeesData[0].lastName}`}
                src={employeesData[0].profilePic}
                style={{
                  marginLeft: isMobile ? 0 : 0, // Remove left margin on mobile
                  marginBottom: isMobile ? 10 : 0, // Add bottom margin on mobile
                  height: "65px",
                  width: "65px",
                }}
              />

              <Box
                ml={isMobile ? 0 : 2}
                mt={isMobile ? 1 : 0}
                textAlign={isMobile ? "center" : "left"}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "black", fontWeight: "bold", fontSize: "20px" }}
                >
                  {employeesData[0].firstName} {employeesData[0].lastName}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "black", fontSize: "15px" }}
                >
                  Employee Id: {employeesData[0].employeeId}
                </Typography>
              </Box>
            </Box>
          )
        )}
        <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            sx={{ width: isMobile ? "100%" : "120px" }}
          >
            {Array.from({ length: 12 }, (_, i) => (
              <MenuItem key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            sx={{ width: isMobile ? "100%" : "120px" }}
          >
            {[2024, 2025].map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGetClick}
            sx={{ width: isMobile ? "100%" : "120px" }}
          >
            Get
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2} mb={5} mt={5}>
        <Grid item xs={12} md={3}>
          <StatusCard status="Present">
            <Typography variant="h6" style={{color:'white'}}>Present</Typography>
            <Typography variant="h4" style={{color:'white'}}>{presentCount}</Typography>
          </StatusCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusCard status="Absent">
            <Typography variant="h6" style={{color:'white'}}>Absent</Typography>
            <Typography variant="h4" style={{color:'white'}}>{absentDatesCount}</Typography>
          </StatusCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusCard status="halfDay">
            <Typography variant="h6" style={{color:'white'}}>Half Days</Typography>
            <Typography variant="h4" style={{color:'white'}}>{halfDayCount}</Typography>
          </StatusCard>
        </Grid>

        <Grid item xs={12} md={3}>
          <StatusCard status="week off">
            <Typography variant="h6" style={{color:'white'}}>Week Offs</Typography>
            <Typography variant="h4" style={{color:'white'}}>{weekOffCount}</Typography>
          </StatusCard>
        </Grid>
      </Grid>
      <StyledCalendar
        value={calendarDate}
        onChange={(date) => {
          setCalendarDate(date);
          setSelectedYear(date.getFullYear());
          setSelectedMonth(date.getMonth());
        }}
        tileClassName={tileClassName}
        tileDisabled={tileDisabled}
        onClickDay={onDateChange}
      />

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer} // Ensure this is correctly bound
      >
        <Box sx={{ width: 400, padding: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Edit Attendance Details
            </Typography>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedDate && (
            <Typography variant="body1" sx={{ marginY: 2, fontSize: "20px" }}>
              Date: {selectedDate.toLocaleDateString()}
            </Typography>
          )}
          <hr />
          <Box sx={{ display: "flex", gap: 1, marginTop: "30px" }}>
            <Button
              variant="contained"
              color="error"
              sx={{ width: "100%", height: "50px", marginTop: "20px",fontWeight:'bold' }}
              onClick={() => handleAttendanceChange("Absent")}
              disabled={loadingButton === "Absent"} // Disable button while loading
            >
              {loadingButton === "Absent" ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Absent"
              )}
            </Button>
            <Button
              variant="contained"
              
              sx={{ width: "100%", height: "50px", marginTop: "20px", backgroundColor:'#5bb659', fontWeight:'bold' }}
              onClick={() => handleAttendanceChange("Present")}
              disabled={loadingButton === "Present"} // Disable button while loading
            >
              {loadingButton === "Present" ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Present"
              )}
            </Button>
            {/* <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "45px",
                marginTop: "20px",
                backgroundColor: "#A09F9F",
              }}
              onClick={() => handleAttendanceChange("Week Off")}
              disabled={loadingButton === "Week Off"} // Disable button while loading
            >
              {loadingButton === "Week Off" ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Week Off"
              )}
            </Button> */}
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "50px",
                marginTop: "20px",
                backgroundColor: "#7b81e1",
                fontWeight:'bold'
              }}
              onClick={() => handleAttendanceChange("halfDay")}
              disabled={loadingButton === "halfDay"} // Disable button while loading
            >
              {loadingButton === "halfDay" ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "halfDay"
              )}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Container>
  );
};

export default EmployeeAttendanceDetails;
