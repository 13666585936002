import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"; // Import BrowserRouter here
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Topbar from "./pages/global/Topbar";
import Dashboard from "./pages/dashboard/dashboard";
import SignIn from "./pages/signin/signin";
import OrganisationForm from "./pages/organisationForm/organisationForm";
import Employee from "./pages/Employee/employee";
import EmployeeRegForm from "./pages/EmployeeRegistration/EmployeeRegistration";
import EmployeeAttendance from "./pages/Attedance/attendance";
import EmployeeLeave from "./pages/Leave/leave";
import EmployeePaySlip from "./pages/PaySlip/paySlips";
import EmployeeDetails from "./pages/EmployeeDetails/EmployeeDetails";
import EmployeeAttendanceDetails from "./pages/EmployeeAttendanceDetails/EmployeeAttendanceDetails";
import Profile from "./pages/Profile/Profile";
import PaySlipDoc from "./pages/PaySlipDocument/DocPaySlip";
import AbsentsList from "./pages/AbsentsList/absentsList";
import ChangePassword from "./pages/ChangePassword/changePassword";

const App = () => {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(false);
  
  const checkAuthentication = () => {
    const publicRoutes = ["/", "/signup"];
    const isPublicRoute = publicRoutes.includes(location.pathname);
    setAuthenticated(!isPublicRoute);
  };

  useEffect(() => {
    checkAuthentication();
  }, [location.pathname]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {authenticated ? (
          <MyProSidebarProvider>
            <div style={{ height: "100%", width: "100%" }}>
              <Topbar />
              <main>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/employee" element={<Employee />} />
                  <Route path="/employeeRegForm" element={<EmployeeRegForm />} />
                  <Route path="/employeeAttendance" element={<EmployeeAttendance />} />
                  <Route path="/leaves" element={<EmployeeLeave />} />
                  <Route path="/paySlip" element={<EmployeePaySlip />} />
                  <Route path="/paySlipDocument" element={<PaySlipDoc/>}/>
                  <Route path="/employeeDetails/:empId" element={<EmployeeDetails />} />
                  <Route path="/attendance/:empId" element={<EmployeeAttendanceDetails />} />
                  <Route path="/profile" element={<Profile/>} />
                  <Route path="/absentsList" element={<AbsentsList/>} />
                  <Route path="/changePassword" element={<ChangePassword/>} />
                </Routes>
              </main>
            </div>
          </MyProSidebarProvider>
        ) : (
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/signup" element={<OrganisationForm />} />
          </Routes>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
